import styled from "styled-components";
import StyledIcon from "components/Icon";

import theme from "config/theme";

import { ButtonSimple } from "components/Button";
import { Icon } from "components/List/PublicationItem/styles";

export const Title = styled.div`
  width: 100%;
  padding: 20px 0;
  color: ${theme.color.red};
  font-size: ${theme.font.size.theta};
  transition: ${theme.transition};
  border-top: 2px solid ${theme.color.paper2};
  text-align: center;

  a {
    color: ${theme.color.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${theme.size.tablet}) {
    line-height: 1.4;
    padding: 20px 0;
    font-size: ${theme.font.size.delta};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;

  &:first-of-type ${Title} {
    border-top: none;
  }

  &:hover {
    ${Icon} {
      color: ${theme.color.primary};
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const MyIcon = styled(StyledIcon)`
  margin: auto;
  align-self: center;
  color: ${theme.color.gray4};
`;

export const KeysBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

export const InnerKeys = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const MyIconWrapper = styled(ButtonSimple)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  &:hover {
    ${MyIcon} {
      color: ${theme.color.primary};
    }
  }
`;
