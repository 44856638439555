import styled from "styled-components";

import theme from "config/theme";

import StyledIcon from "components/Icon";

export const Icon = styled(StyledIcon)`
  margin-right: 20px;
  color: ${theme.color.gray4};
`;

export const Title = styled.div`
  width: 95%;
  padding: 15px 0;
  color: ${theme.color.red};
  font-size: ${theme.font.size.theta};
  transition: ${theme.transition};
  border-top: 3px solid ${theme.color.paper2};

  a {
    color: ${theme.color.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${theme.size.tablet}) {
    line-height: 1.4;
    padding: 20px 0;
    font-size: ${theme.font.size.delta};
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
`;

export const KeysBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 0;
  width: 90%;
`;

export const InnerKeys = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;

  &:first-of-type ${Title} {
    border-top: none;
  }

  &:hover {
    ${Icon} {
      color: ${theme.color.primary};
    }
  }
`;
