import styled from "styled-components";

import theme from "config/theme";

import StyledIcon from "components/Icon";
import { ButtonSimple } from "components/Button";

export const Icon = styled(StyledIcon)`
  margin-right: 20px;
  color: ${theme.color.gray4};
`;

export const Title = styled.div`
  width: 100%;
  padding: 32px 0;
  color: ${theme.color.gray2};
  font-size: ${theme.font.size.upsilon};
  transition: ${theme.transition};
  border-top: 1px solid ${theme.color.paper2};

  @media (max-width: ${theme.size.tablet}) {
    line-height: 1.4;
    padding: 20px 0;
    font-size: ${theme.font.size.delta};
  }
`;

export const Wrapper = styled(ButtonSimple)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;

  &:first-of-type ${Title} {
    border-top: none;
  }

  &:hover {
    ${Icon} {
      color: ${theme.color.primary};
    }

    ${Title} {
      color: ${theme.color.gray};
    }
  }
`;
