import React from "react";

import { Text } from "components/Text";
import { Wrapper, Icon, Title, Inner, InnerKeys, KeysBlock } from "./styles";

const PublicationItem = ({ content }) => {
  return (
    <Wrapper to={content.path}>
      <Inner>
        <Title>
          <a href={content.path}>{content.title}</a>
        </Title>
        <Text>{content.excerpt}</Text>

        <KeysBlock>
          <InnerKeys>
            <Icon icon="avatar" />
            <Text> {content.authors} </Text>
          </InnerKeys>
          <InnerKeys>
            <Icon icon="arrow" />
            <Text> {content.tags}</Text>
          </InnerKeys>
          <InnerKeys>
            <Icon icon="clock" />
            <Text> {content.date}</Text>
          </InnerKeys>
        </KeysBlock>
      </Inner>
    </Wrapper>
  );
};

export default PublicationItem;
