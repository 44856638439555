import React from "react";

import {
  Wrapper,
  Inner,
  InnerKeys,
  KeysBlock,
  Title
} from "components/List/ConferencePresentedItem/styles";
import { Text } from "components/Text";

const ConferenceAttendedItem = ({ content }) => {
  return (
    <Wrapper to={content.path}>
      <Inner>
        <Title>
          <a href={content.path}>{content.title}</a>
        </Title>

        <KeysBlock>
          <InnerKeys>
            <Text> {content.location}</Text>
          </InnerKeys>
          <InnerKeys>
            <Text> {content.date}</Text>
          </InnerKeys>
        </KeysBlock>
      </Inner>
    </Wrapper>
  );
};

export default ConferenceAttendedItem;
