import styled from "styled-components";
import { space } from "styled-system";

import theme from "config/theme";

import ListItem from "./ListItem";
import ConferenceAttendedItem from "./ConferenceAttendedItem";
import ConferencePresentedItem from "./ConferencePresentedItem";
import PublicationItem from "./PublicationItem";

const List = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;

  ${space};

  @media (max-width: ${theme.size.tablet}) {
    margin: 30px auto 70px;

    ${space};
  }
`;

export default List;
export { ListItem };
export { ConferenceAttendedItem };
export { ConferencePresentedItem };
export { PublicationItem };
