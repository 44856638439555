import styled from "styled-components";
import styledMap from "styled-map";
import { transparentize } from "polished";
import { space } from "styled-system";

import theme from "config/theme";

import Grid from "components/Grid";
import { H1 } from "components/Text";
import StyledButton from "components/Button";
import StyledImage from "components/Image";

export const Wrapper = styled.div`
  padding-top: 80px;

  background-color: ${styledMap("shade", {
    dark: theme.color.gray,
    paper: theme.color.paper2,
    light: theme.color.paper,
    default: "white"
  })};
`;

export const Inner = styled(Grid)`
  position: relative;
  padding-top: 100px;
  padding-bottom: 130px;
  padding-right: 650px;

  ${space};

  @media (max-width: ${theme.size.desktop}) {
    padding-right: 450px;

    ${space};
  }

  @media (max-width: 55em) {
    padding-top: 50px;
    padding-right: 350px;

    ${space};
  }

  @media (max-width: ${theme.size.tablet}) {
    padding-bottom: 110px;
    padding-right: 30px;

    ${space};
  }

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 70px);
    right: -320px;
    transform: translateY(-50%);
    width: 1200px;
    height: 110%;
    display: ${props => (props.hasImage ? "block" : "none")};
    background-image: radial-gradient(
      50% 49%,
      ${transparentize(0.6, theme.color.gray2)} 35%,
      ${transparentize(0.8, theme.color.gray2)} 55%,
      ${transparentize(1, theme.color.gray2)} 90%
    );

    @media (max-width: ${theme.size.tablet}) {
      top: 180px;
      left: 50%;
      right: auto;
      transform: none;
      width: 900px;
      height: 80%;
      opacity: 0.8;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Heading = styled(H1)`
  z-index: 2;
  position: relative;
  margin: 0 auto 25px;

  color: ${styledMap("shade", {
    dark: "white",
    default: theme.color.gray
  })};

  @media (max-width: 55em) {
    font-size: ${theme.font.size.cesar};
  }

  @media (max-width: ${theme.size.tablet}) {
    text-align: center;
  }
`;

export const Subheading = styled.p`
  z-index: 2;
  margin: 0 auto;
  position: relative;
  font-size: ${theme.font.size.theta};
  font-weight: ${theme.font.weight.regular};

  color: ${styledMap("shade", {
    dark: transparentize(0.2, theme.color.paper),
    default: theme.color.gray2
  })};

  @media (max-width: 55em) {
    font-size: ${theme.font.size.delta};
  }

  @media (max-width: ${theme.size.tablet}) {
    text-align: center;
    font-size: ${theme.font.size.delta};
  }
`;

export const Button = styled(StyledButton)`
  margin-top: 60px;

  @media (max-width: ${theme.size.tablet}) {
    display: table;
    margin: 40px auto 0;
  }
`;

export const Image = styled(StyledImage)`
  position: absolute !important;
  top: calc(50% - 70px);
  right: -60px;
  width: 720px;
  height: auto;
  transform: translateY(-50%);

  @media (max-width: 70em) {
    width: 650px;
    right: -40px;
  }

  @media (max-width: ${theme.size.desktop}) {
    width: 450px;
    right: -20px;
  }

  @media (max-width: 55em) {
    width: 380px;
  }

  @media (max-width: ${theme.size.tablet}) {
    position: relative !important;
    top: auto;
    right: auto;
    width: 100%;
    max-width: 500px;
    display: table;
    margin: -60px auto 20px;
    transform: none;
  }
`;
