import React from "react";

import { Text } from "components/Text";
import {
  Wrapper,
  Inner,
  MyIcon,
  InnerKeys,
  KeysBlock,
  Title,
  MyIconWrapper
} from "./styles";

const ConferencePresentedItem = ({ content }) => {
  return (
    <Wrapper to={content.path}>
      <Inner>
        <Title>
          <a href={content.path}>{content.title}</a>
        </Title>

        <KeysBlock>
          <InnerKeys>
            <Text> {content.location}</Text>
          </InnerKeys>
          <InnerKeys>
            <Text> {content.date}</Text>
          </InnerKeys>
        </KeysBlock>
        {content.presentation_link && (
          <MyIconWrapper to={content.presentation_link}>
            <MyIcon icon="youtube" size="65px" />
          </MyIconWrapper>
        )}
      </Inner>
    </Wrapper>
  );
};

export default ConferencePresentedItem;
