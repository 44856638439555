import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { HeroFeature } from "components/Hero";
import { PublicationItem } from "components/List";
import { BlockWrapper } from "components/Block";

import { Heading } from "templates/home-page/Hero/styles";

export const PublicationsPageTemplate = ({ page, markdown }) => {
  return (
    <>
      <HeroFeature content={page.hero} />

      <Heading> </Heading>
      <BlockWrapper
        shade="light"
        pt={["50px", "50px", "60px"]}
        pb={["40px", "40px", "50px"]}
        px={["0", "0", "0", "10px"]}
      >
        <Heading>Publications</Heading>
        {page.publications.map((publication, index) => (
          <PublicationItem content={publication} />
        ))}
      </BlockWrapper>
      <Heading> </Heading>
    </>
  );
};

const PublicationsPage = ({ data }) => {
  const { frontmatter: page } = data.allMarkdownRemark.edges[0].node;
  return (
    <Layout page={page} shade="light" headerShade="light" footerShade="light">
      <PublicationsPageTemplate page={page} />
    </Layout>
  );
};

PublicationsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default PublicationsPage;

export const PublicationsPageQuery = graphql`
  query PublicationsPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "publications-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            page {
              title
            }
            hero {
              heading
              subheading
            }
            publications {
              title
              authors
              date
              path
              excerpt
              tags
            }
          }
        }
      }
    }
  }
`;
